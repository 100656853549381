import * as React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import moment from 'moment'
import theme from '../atoms/theme'
import { Link } from 'gatsby'

const PreviewContainer = styled.div`
  max-width: ${(props: Props): string => (props.fullWidth ? '100%' : '33.33%')};
  flex: 1 0 ${(props: Props): string => (props.fullWidth ? '100%' : '33.33%')};
  margin-bottom: ${(props: Props): string => (props.fullWidth ? '7.4rem' : '')};
  &:not(:last-child) {
    padding-right: 3.2rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    max-width: 100%;
    padding-right: 0 !important;
    flex-direction: column;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: ${(props: Props): string =>
    props.fullWidth ? 'row' : 'column'};
  cursor: pointer;
  &:hover {
    h3 {
      color: ${theme.colours.quaternary};
    }
    .content {
      transition: transform 0.2s ease-in-out;
      transform: ${(props: Props): string =>
        props.fullWidth ? '' : 'translateY(-5rem)'};
    }
  }
`

const Date = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  font-family: ${theme.fonts.primary};
  color: ${theme.colours.darkGrey2};
  text-transform: uppercase;
  margin-bottom: 0.8rem;
`

const Title = styled.h3`
  margin-bottom: 1.6rem;
`

const Image = styled(Img)`
  height: ${(props: Props): string => (props.fullWidth ? 'auto' : '22rem')};
  min-width: ${(props: Props): string => (props.fullWidth ? '50rem' : 'auto')};
  margin-right: ${(props: Props): string => (props.fullWidth ? '3.1rem' : '0')};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-right: 0;
    min-width: auto;
  }
`

const PreviewText = styled.h5`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 2.8rem;
  font-weight: 400;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  padding-top: ${(props: Props): string => (props.fullWidth ? '0' : '2.4rem')};
  background: ${theme.colours.white};
  transition: transform 0.2s ease-in-out;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-top: 2.4rem;
  }
`

interface Article {
  image: {
    fluid
  }
  date: string
  title: string
  slug: string
  previewText: string
  body: {
    json
  }
}

interface Props extends Article {
  fullWidth?: boolean
}

const StyledLink = styled(Link)`
  text-decoration: none;
`

const NewsArticlePreview = (props: Props): JSX.Element => {
  return (
    <PreviewContainer {...props}>
      <StyledLink to={`/news/${props.slug}`}>
        <InnerContainer {...props}>
          <Image {...props} fluid={props.image.fluid} />
          <Content {...props} className="content">
            <Date>{moment(props.date).format('DD MMM YYYY')}</Date>
            <Title>{props.title}</Title>
            <PreviewText>{props.previewText}</PreviewText>
          </Content>
        </InnerContainer>
      </StyledLink>
    </PreviewContainer>
  )
}

export default NewsArticlePreview
